.tutorial {
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background: lightblue;

  h2 {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
  }
  p {
    font-size: 1.5rem;
    padding: 2rem;
  }
  @media (max-width: $MobileWidth) {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
}
