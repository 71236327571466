.game {
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: lightblue;
  align-items: center;
  h2 {
    margin: 0rem;
  }
  @media (max-width: $MobileWidth) {
    h2 {
      font-size: 1.3rem;
    }
  }
}

.container {
  width: 1060px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: $MobileWidth) {
    width: 400px;
    margin: none;
  }
}

#cards {
  width: 1060px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $MobileWidth) {
    width: 400px;
    margin: 0px;
  }
}

.card {
  width: 160px;
  height: 160px;
  margin-bottom: 20px;

  @media (max-width: $MobileWidth) {
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 100px;
    margin-bottom: 0px;
  }
}
.card:not(:nth-child(6n)) {
  margin-right: 20px;
  @media (max-width: $MobileWidth) {
    margin-right: 0px;
  }
}

.c {
  margin: 0.2rem;
  position: absolute;
  max-width: 160px;
  max-height: 160px;
  width: 50ch;
  height: 50ch;
  cursor: pointer;

  will-change: transform, opacity;
  @media (max-width: $MobileWidth) {
    max-width: 80px;
    max-height: 80px;
    width: 20ch;
    height: 20ch;
  }
}

.front,
.back {
  background-size: cover;
}

.back {
  border: 5px solid rgb(58, 138, 165);
  border-radius: 20px;
}

.front {
  border: 5px solid rgb(58, 138, 165);
  border-radius: 20px;
}
//This controls the hover effect over the cards
// box-shadow: 0 5px 2px rgba(0, 0, 0, 0.15);
// transition: box-shadow 0.3s ease-in-out;
// &:hover {
//   box-shadow: 0px 5px 10px #474747;
// }

.timer {
  margin: 0 0 0 1rem;
}

.difficulty {
  @media (max-width: $TabletWidth) {
    display: flex;
    flex-direction: column;
    padding: none;
  }
}
