@import "./_variables";
@import "./_home";
@import "./_about";
@import "./_tutorial";
@import "./_game";
@import "./_buttons";
@import "./_nav";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  background: $AppBackgroundColour;
}
