.about {
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  h2 {
    padding: 0.5rem;
    font-size: 1.8rem;
  }
  p {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
  @media (max-width: $MobileWidth) {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
}

.main-body {
  text-align: left;
  padding-left: 1rem;
}
