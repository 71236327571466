// These 3 are from the game file.
.top-nav {
  margin-top: 15vh;
}

.logo-title-div {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}
.logo-title {
  font-family: $LogoFont;
  text-align: left;
  font-size: 2rem;
  margin: 1rem 1rem 0rem 1rem;
}
.brain-logo {
  width: 0rem;
  border-radius: 0px;
  margin-top: 1rem;
  z-index: 100;
  @media (max-width: $MobileWidth) {
    width: 1rem;
  }
}
// These ones are from Home
h1 {
  text-align: center;
  display: block;
  margin: 2rem;
  font-family: $LogoFont;
  font-size: 4rem;
}
.brain-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  z-index: 100;
}
//Home button behavior is here
.home-button {
  margin: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  padding: 5px 5px;
  border-radius: 15px;
  border: none;
  z-index: 1;
}
//Changes the size of the house
.home-pic {
  width: 2.5rem;
  border: none;
  background: none;
  @media (max-width: $TabletWidth) {
    width: 1.5rem;
  }
}
.restart {
  background: none;
  width: 2.5rem;
  border: none;
  @media (max-width: $TabletWidth) {
    width: 1.7rem;
  }
}
.restart-button {
  margin: 4.3rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  padding: 5px 5px;
  border-radius: 15px;
  border: none;
  z-index: 1;
  @media (max-width: $TabletWidth) {
    margin: 10% 2.6%;
  }
}
